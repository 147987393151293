
let apiRoot = "https://pubsub.newgen.co/";

if(window.location.host=="localhost:3000")
{
    apiRoot = "https://pubsub.newgen.co/";
}


export const mailqueuelist = apiRoot + "reportsmicro/reports/mailqueuelist_for_user?userId=";
export const getuseremails =(limit,pageid,status,mailbox)=>apiRoot+'reportsmicro/reports/correspondences/getuseremails?&limit=' + limit + '&page_no=' + pageid + '&mailBoxType=' + status + '&status=' + status + '&email=' + mailbox
export const getemailbyfuzzyid=(fuzzyid,mailbox)=>apiRoot+'reportsmicro/reports/correspondences/getemailbyfuzzyid/' + fuzzyid + '/' + mailbox
export const sendmail='https://pubkit.newgen.co/office/api/mobile/sendmail'
export const replymail=(id)=>'https://pubkit.newgen.co/office/api/mobile/replymail/' + id
export const userlist=(q)=>"https://pubsub.newgen.co/reportsmicro/reports/correspondences/getemailslike?q="+q
export { apiRoot}