import './App.css';
import 'antd/dist/antd.css';
import { CaretDownOutlined, SendOutlined, ArrowLeftOutlined, UserOutlined, AlignCenterOutlined, PaperClipOutlined, FormOutlined, LoadingOutlined, RollbackOutlined } from '@ant-design/icons';
import { Layout, Spin, Button, Input, Select, message, Tabs, List, Popover, Result } from 'antd';
import React, { useState, useEffect, } from 'react';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mailqueuelist, getuseremails, getemailbyfuzzyid, sendmail, replymail,userlist } from "./Config";
import moment from 'moment';
import store from 'store2';
import axios from 'axios';
const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;

export default function DomainMails(props) {
    const [loadings, setLoadings] = useState(false);
    const [maillist, setmaillist] = useState([])
    const [mailloading, setmailloading] = useState(false)
    const [mailloadingmore, setmailloadingmore] = useState(false)
    const [nomore, setnomore] = useState(false)
    const [limit, setlimit] = useState(10)
    const [page, setpage] = useState(1)
    const [mailBox, setMailBox] = useState()
    const [mailBoxList, setMailBoxList] = useState()
    const [ismailclick, setismailclick] = useState(false)
    const [ismailfetching, setismailfetching] = useState(true)
    const [mailbody, setmailbody] = useState()
    const [activeTab, setActiveTab] = useState("open")
    const [activemaile, setActivemail] = useState()
    const [status, setStatus] = useState("OPEN")
    const [mailcontent, setmailcontent] = useState("")
    const [mailTo, setmailTo] = useState([])
    const [mailCc, setmailCc] = useState([])
    const [mailBcc, setmailBcc] = useState([])
    const [mailSubject, setmailSubject] = useState("")
    const [mailsending, setmailsending] = useState(false)
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const history = createBrowserHistory();
    const [searchParams, setSearchParams] = useSearchParams();
    const header = store.get('pubkitmobile_auth')
    const user = store.get('pubkitmobile_user')

    useEffect(() => {
       
        const queryString = history.location.search;
        const query = new URLSearchParams(queryString);
        if (!header || !user) {
             setTimeout( ()=> {
                window.location.href="/"
                return;
         }, 3000);
             
        }
        if (pathname == "/home") {
            let queue = query.get("mailqueue")
            let tap = query.get("tap")
            if (queue && tap) {
                getMailQueue(queue)
                setActiveTab(tap)
                setMailBox(queue)
                getMails(tap, queue)

            } else {
                ingetMailQueue()
                

            }

        } else if (pathname == "/mail") {
            let queue = query.get("mailqueue")
            let fuzzyid = query.get("fuzzyid")
            if (queue && fuzzyid) {
                getMailQueue()
                setActiveTab(activeTab)
                setMailBox(queue)
                getMailBody(queue, fuzzyid)
                getMailQueue()
                getMails(activeTab, queue)
            }
        } else if (pathname == "/compose" || pathname == "/replyall") {
            navigate({ pathname: '/home' })
            getMailQueue()
            setActiveTab('open')
            setMailBox(user.email)
            getMails('open', user.email)

        }

    }, []);




    const getMailQueue = (q) => {
        axios({ url: mailqueuelist + user.id, method: 'get', headers: header })
            .then((response) => {
                setMailBoxList(response.data);
            })
            .catch((error) => {
                setError(true)
                console.log(error)
            });
    }
    const ingetMailQueue = (q) => {
        axios({ url: mailqueuelist + user.id, method: 'get', headers: header })
            .then((response) => {
                if(!response.data.length<=0){
                    setMailBoxList(response.data);
                    let ishave = response.data.find(o => o.correspond_address === user.email );
                    if(ishave){
                        setActiveTab('open')
                        setMailBox(user.email)
                        getMails('open', user.email)                 
                    }else{
                        setActiveTab('open')
                        setMailBox(response.data[0].correspond_address)
                        getMails('open', response.data[0].correspond_address)   
                    }
                }else{
                    message.error('You dont have any mailqueue');

                }
               

            })
            .catch((error) => {
                setError(true)
                console.log(error)
            });
    }


    const getUserList = (q) => {
        setFetching(true)
        axios({ url: userlist(q) , method: 'post', headers: header })
            .then((response) => {
                    let userlist=response.data.map((user) => ({
                        label:user ,
                        value: user,
                      }))
                      setOptions(userlist);
    
                    setFetching(false)
              
               

            })
            .catch((error) => {
                setOptions([]);
                setFetching(false)
                console.log(error)
            });
    }

    const getMails = async (key, mail) => {
        setmailloading(true)
        let status = "open"
        let mailqueu = mail
        console.log("mail", mailqueu)
        if (key) { status = key; setActiveTab(key) }
        axios({ url: getuseremails(10, 1, status, mailqueu), method: 'post', headers: header })
            .then(function (response) {
                setmaillist(response.data.mails)
                if (response.data.mails.length < 10) {
                    setnomore(true)
                } else {
                    setnomore(false)
                }
                setmailloading(false)
                setmailloadingmore(false)
            })
            .catch(function (error) {
                setError(true)
                setmaillist([])
                setmailloading(false)
            });
    }
    const getMoreMails = async (pageid) => {
        axios({ url: getuseremails(10, pageid, status, mailBox), method: 'post', headers: header })
            .then(function (response) {
                setmaillist(prevTime => prevTime.concat(response.data.mails))
                if (response.data.mails.length < 10) {
                    setnomore(true)
                } else {
                    setnomore(false)
                }
                setmailloading(false)
                setmailloadingmore(false)
            })
            .catch(function (error) {
                setmailloading(false)
            });
    }
    const getMailBody = (mailbox, fuzzyid) => {

        setismailfetching(true)
        axios({ url: getemailbyfuzzyid(fuzzyid, mailbox), method: 'post', headers: header })
            .then(response => {
                setmailbody(response.data)
                setStatus(response.data.status)
                setismailfetching(false)

            })
            .catch((error) => {
                setError(true)

                setismailfetching(false)
            });
    }
    const sendMail = () => {
        setmailsending(true)
        let data = {
            "emailFrom": mailBox,
            "emailTo": mailTo.toString(),
            "emailCc": mailCc.toString(),
            "emailBcc": mailBcc.toString(),
            "emailSubject": mailSubject,
            "emailBody": mailcontent
        }
        axios({ url: sendmail, data, method: 'post', headers: header })
            .then(response => {
                if (response.data.message == "Correspondence created") {
                    message.success('Mail Send Succesfully');
                    setmailsending(false)
                    resetfiled()
                    setTimeout(() => {
                        navigate({ pathname: '/home' })
                        setismailclick(false)
                    }, 1000);

                }
            })
            .catch(error => console.log('error', error));

    }
    const resendMail = () => {
        setmailsending(true)
        let data = {
            "emailFrom": mailBox,
            "emailTo": mailTo.toString(),
            "emailCc": mailCc.toString(),
            "emailBcc": mailBcc.toString(),
            "inReplyTo": mailbody.message_id,
            "emailSubject": mailSubject,
            "emailBody": mailcontent
        };
        axios({ url: replymail(mailbody.id), data, method: 'post', headers: header })
            .then(response => {
                if (response.data.message == "Correspondence created") {
                    message.success('Mail Send Succesfully');
                    setmailsending(false)
                    resetfiled()
                    setTimeout(() => {
                        navigate({ pathname: '/home' })
                        setismailclick(false)
                    }, 1000);


                }
            })
            .catch(error => console.log('error', error));

    }
    const handleChange = (value) => {
        setismailclick(false)
        setMailBox(value)
        getMails(activeTab, value)
        history.push(`?mailqueue=${value}&tap=${activeTab}`);
       
    };

    const handleChangeTo = (value) => {
        setmailTo(value)
    };
    const handleChangeCc = (value) => {
        setmailCc(value)
    };
    const handleChangeBcc = (value) => {
        setmailBcc(value)
    };
    const handleChangeSubject = (e) => {
        setmailSubject(e.target.value)
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };



    const resetfiled = () => {
        setmailTo([])
        setmailCc([])
        setmailBcc([])
        setmailSubject("")
        setmailcontent("")
    }

    const sendmailvalidation = () => {
        if (!mailTo.toString()) {
            message.error('To is Missng');
        } else if (!mailSubject) {
            message.error('Subject is Missng');

        } else if (!mailcontent) {
            message.error('body  is Missng');

        } else {
            sendMail()
        }
    }
    const resendmailvalidation = () => {
        if (!mailTo.toString()) {
            message.error('To is Missng');
        } else if (!mailSubject) {
            message.error('Subject is Missng');

        } else if (!mailcontent) {
            message.error('body  is Missng');

        } else {
            resendMail()
        }
    }

    const goToResend = () => {
        resetfiled();
        setLoadings(true)
        let signature = ""
        mailBoxList.map((item) => {
            if (item.correspond_address == mailBox) {
                signature = item.signature
            }
        })
        setmailcontent("....."+signature + "<blockquote>" + mailbody.content + "</blockquote>")
        setmailSubject("Re:" + mailbody.subject)
        let to = mailbody.to.map(item => item.email);
        let cc = mailbody.cc.map(item => item.email);
        let bcc = mailbody.bcc.map(item => item.email);
        to.push(mailbody.from)
        let finalto = to.filter(e => e !== mailBox)
        let finalcc = cc.filter(e => e !== mailBox)
        let finalbcc = bcc.filter(e => e !== mailBox)
        setmailTo(finalto)
        setmailCc(finalcc)
        setmailBcc(finalbcc)
        setLoadings(false)
        navigate({ pathname: '/replyall' })
    }

    const goToCompose = () => {
        resetfiled();
        let signature = ""
        mailBoxList.map((item) => {
            if (item.correspond_address == mailBox) {
                signature = item.signature
            }
        })
        setmailcontent(signature)
        navigate({ pathname: '/compose' })


    }




    const loadMore = !mailloading && !nomore ? (
        <div
            style={{
                textAlign: 'center',
                marginTop: 12,
                marginBottom: 10,
                height: 32,
                lineHeight: '32px',
            }}
        >
            {mailloadingmore ? <Button type="link" >Loading...</Button> : <Button type="link" onClick={() => { setpage(page + 1); setmailloadingmore(true); getMoreMails(page + 1); }}>Load More</Button>}
        </div>
    ) : null;

    const listrender = () => {
        return (
            <List
                itemLayout="horizontal"
                dataSource={maillist}
                loading={mailloading}
                loadMore={loadMore}
                renderItem={item => (
                    <List.Item
                        actions={[<div className='list_sub'>{moment(item.created_at).format('h:mm a')}<br></br>{moment(item.created_at).format('DD/MM/YYYY')}<br></br>{!item.attachment == 0 ? <PaperClipOutlined style={{ fontSize: '17px', }} /> : null}</div>]}>
                        <List.Item.Meta onClick={() => { navigate({ pathname: '/mail', search: `?mailqueue=${mailBox}&fuzzyid=${item.fuzzy_id}` }); getMailBody(mailBox, item.fuzzy_id) }}
                            title={<div className='listtitle'>{!item.subject ? '(No Subject)' : item.subject}</div>}
                            description={<div className='list_sub'>
                                {activeTab == "sent" ? null : <div className='list_sub_item'>From:{item.from}</div>}
                                {activeTab == "open" || activeTab == "assigned_me" ? null : <div className='list_sub_item'>To:{item.to_address.toString()}</div>}
                            </div>}
                        />
                    </List.Item>
                )}
            />
        )
    }
    const pageheader = () => {
        return (
            <div className='home_header'>
                <Popover
                    content={<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className='user_logo'><UserOutlined style={{ color: '#fff', fontSize: '18px', }} /></div>
                        <div>{user.fullName}</div>
                        <div>{user.email}</div>
                        <Button type="primary" block onClick={() => { store.clearAll(); window.location.href="/" }}>Logout</Button>
                        <div style={{ color: '#c4c4c4', fontSize: '14px', }}>Version: 1.8</div>


                    </div>}
                    title={false}
                    trigger="click"
                    open={open}
                    onOpenChange={handleOpenChange}>
                    <div className='name_head'>
                        <div className='user_logo'><UserOutlined style={{ color: '#fff', fontSize: '18px' }} /></div>
                    </div>
                </Popover>
                {pathname == "/home" ? <div className='mail_box'>
                    {mailBoxList ? <Select size="large" defaultValue={mailBox} bordered={false} suffixIcon={false} style={{ fontSize: '18px', color:'#000000' }} onChange={handleChange}>
                        {mailBoxList.map((item) => <Option value={item.correspond_address}>{item.correspond_address}</Option>)}
                    </Select> : null}
                    <CaretDownOutlined style={{ color: '#ff9900', marginRight: "20px" }} />
                </div> : <div className='mail_box'><div style={{ fontSize: '18px', }}>{mailBox}</div></div>}
                <AlignCenterOutlined style={{ fontSize: '18px', color: '#fff' }} />
            </div>
        )
    }

    const getmailbodyui = () => {
        const iconStyle = { fontSize: '20px', color: '#503925' }
        if (ismailfetching) {
            return <div className='home'>
                {pageheader()}

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <Spin />
                </div>
            </div>
        } else {
            return (
                <div className='home'>
                    {pageheader()}

                    <div className='mail_list'>
                        <div className='mailcontiner'>
                            <div className='mailbodyheader'>
                                <div className='mailbodyheader_main'>
                                    <ArrowLeftOutlined style={{ marginRight: '5px' }} onClick={() => { navigate(-1) }} />
                                    {!mailbody.subject ? '(No Subject)' : mailbody.subject}

                                </div>

                                <div className='mailbodyheader_sub'>
                                    <span>From:</span>{mailbody.from} <br></br>
                                    <span>To:</span>{mailbody.to.map((item, i, arr) => { if (arr.length - 1 === i) { return <span className='cc'>{item.email}</span> } else { return <span className='cc'>{item.email},</span> } })}<br></br>
                                    {!mailbody.cc.length <= 0 ? <div><span>Cc:</span>{mailbody.cc.map((item, i, arr) => { if (arr.length - 1 === i) { return <span className='cc'>{item.email}</span> } else { return <span className='cc'>{item.email},</span> } })} </div> : null}
                                    {!mailbody.bcc.length <= 0 ? <div><span>Bcc:</span>{mailbody.bcc.map((item, i, arr) => { if (arr.length - 1 === i) { return <span className='cc'>{item.email}</span> } else { return <span className='cc'>{item.email},</span> } })}</div> : null}
                                    <span>Date:</span> <span className='cc'>{moment(mailbody.created_at).format('dddd, MMMM Do YYYY, h:mm:ss a')}</span> </div>

                            </div>
                            <div className='mailbody'>
                                <div className='mailbodystyle' dangerouslySetInnerHTML={{ __html: mailbody.content }}></div>
                                {!mailbody.attachments.length <= 0 ? <div className='attachments'>
                                    <div className='att_name'>Attachments</div>
                                    {mailbody.attachments.map((item) => <a href={item.url} download>{item.name}</a>)}
                                </div> : null}
                            </div>
                            <div className='mailfooder'>
                                <Button shape="round" loading={loadings} icon={<RollbackOutlined />} size="large" onClick={goToResend}>
                                    Reply all
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }



    const homeScreen = () => {
        return (
            <div className='home'>
                {pageheader()}
                <div className='mail_list'>
                    <Tabs type="card" defaultActiveKey={activeTab} activeKey={activeTab} style={{ width: '100%' }} onChange={(tab) => { history.push(`?mailqueue=${mailBox}&tap=${tab}`); getMails(tab, mailBox) }}>
                        <TabPane tab="Open" key="open"  >
                            {listrender()}
                        </TabPane>
                        <TabPane tab="Assigned(Me)" key="assigned_me">
                            {listrender()}
                        </TabPane>
                        <TabPane tab="Sent" key="sent">
                            {listrender()}
                        </TabPane>
                        
                        <TabPane tab="Resolved" key="resolved">
                            {listrender()}
                        </TabPane>
                    </Tabs>
                    <div className='compose' onClick={goToCompose}>
                        <FormOutlined style={{ fontSize: '20px', color: '#503925' }} />
                    </div>
                </div>

            </div>
        )
    }

    const mailFileds = () => {
        return (
            <>
                <Select
                    mode="tags"
                    className='mail_body_filed'
                    style={{ marginTop: '10px' }}
                    size='large'
                    bordered={false}
                    value={mailTo}
                    showArrow={true}	
                    placeholder="To"
                    onChange={handleChangeTo}
                    onSearch={getUserList}
                     options={options}
                     notFoundContent={fetching ? <Spin size="small" /> : null}

                />
                <Select
                    mode="tags"
                    className='mail_body_filed'
                    size='large'
                    style={{ marginTop: '20px' }}
                    bordered={false}
                    placeholder="Cc"
                    value={mailCc}
                    showArrow={true}	
                    onSearch={getUserList}
                    options={options}
                    onChange={handleChangeCc}
                    notFoundContent={fetching ? <Spin size="small" /> : null}

                />
                <Select
                    mode="tags"
                    className='mail_body_filed'
                    style={{ marginTop: '20px' }}
                    size='large'
                    bordered={false}
                    placeholder="Bcc"
                    value={mailBcc}
                    showArrow={true}
                    onSearch={getUserList}
                    options={options}
                    onChange={handleChangeBcc}
                />
                <input
                    onChange={handleChangeSubject}
                    style={{ marginTop: '20px' }}
                    value={mailSubject}
                    className='inp'
                    size='large'
                    placeholder="Subject" />

                <div style={{ display: 'flex', marginTop: '20px', overflowX: 'scroll', flexDirection: 'column' }}>
                    <CKEditor
                        style={{ display: 'flex', height: '300px' }}
                        editor={ClassicEditor}
                        data={mailcontent}
                        config={{
                            toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable',
                                'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo']
                        }}

                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setmailcontent(data)
                            console.log(data);
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}

                    />
                </div>

            </>
        )
    }

    const compose = () => {

        return (
            <div className='home'>
                {pageheader()}
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                    <div style={{ display: 'flex', width: '100%', height: '40px', alignItems: 'center', paddingLeft: '10px', fontSize: '16px', fontWeight: '500' }} onClick={() => navigate(-1)}  >
                        <ArrowLeftOutlined style={{ marginRight: '5px' }} />       Compose
                    </div>

                    {mailFileds()}
                    <div style={{ display: 'flex', width: '100%', height: '60px', padding: '5px', justifyContent: 'flex-end', alignItems: 'center' }} >
                        {mailsending ? <div style={{ display: 'flex', width: '60px', height: '40px', backgroundColor: '#ff9900', color: '#fff', alignItems: 'center', justifyContent: 'center' }} ><LoadingOutlined /></div> : <div style={{ display: 'flex', width: '60px', height: '40px', backgroundColor: '#ff9900', color: '#fff', alignItems: 'center', justifyContent: 'center' }} onClick={sendmailvalidation}><SendOutlined /></div>}
                    </div>
                </div>
            </div>
        )
    }

    const replyall = () => {

        return (
            <div className='home'>
                {pageheader()}
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                    <div style={{ display: 'flex', width: '100%', height: '40px', alignItems: 'center', paddingLeft: '10px', fontSize: '16px', fontWeight: '500' }} onClick={() => { resetfiled(); navigate(-1) }}  >
                        <ArrowLeftOutlined style={{ marginRight: '5px' }} />   Reply All
                    </div>
                    {mailFileds()}

                    <div style={{ display: 'flex', width: '100%', height: '60px', padding: '5px', justifyContent: 'flex-end', alignItems: 'center' }} >
                        {mailsending ? <div style={{ display: 'flex', width: '60px', height: '40px', backgroundColor: '#ff9900', color: '#fff', alignItems: 'center', justifyContent: 'center' }} ><LoadingOutlined /></div> : <div style={{ display: 'flex', width: '60px', height: '40px', backgroundColor: '#ff9900', color: '#fff', alignItems: 'center', justifyContent: 'center' }} onClick={resendmailvalidation}><SendOutlined /></div>}
                    </div>
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className='home'>
                <div className='home_header'>
                <Popover
                    content={<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className='user_logo'><UserOutlined style={{ color: '#fff', fontSize: '18px', }} /></div>
                        <div>{user.fullName}</div>
                        <div>{user.email}</div>
                        <Button type="primary" block onClick={() => { store.clearAll(); navigate({ pathname: '/' }) }}>Logout</Button>
                        <div style={{ color: '#c4c4c4', fontSize: '14px', }}>Version: 1.5</div>


                    </div>}
                    title={false}
                    trigger="click"
                    open={open}
                    onOpenChange={handleOpenChange}>
                    <div className='name_head'>
                        <div className='user_logo'><UserOutlined style={{ color: '#fff', fontSize: '18px' }} /></div>
                    </div>
                </Popover>
                 <div className='mail_box'><div style={{ fontSize: '18px', }}>{user.email}</div></div>
                <AlignCenterOutlined style={{ fontSize: '18px', color: '#fff' }} />
            </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <Result
                        status="500"
                        title="500"
                        subTitle="Sorry, something went wrong."
                        extra={<Button onClick={() => {window.location.href="/home"}} type="primary">Refresh</Button>}
                    />                </div>
            </div>
        )
    }


    return (
        <Layout>
            <Layout className="site-layout">
                <Content className="site-layout-background"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: '100vh',
                    }}>
                    {(pathname == "/home") && (homeScreen())}
                    {(pathname == "/mail") && (getmailbodyui())}
                    {(pathname == "/compose") && (compose())}
                    {(pathname == "/replyall") && (replyall())}
                </Content>
            </Layout>
        </Layout>
    );
};
