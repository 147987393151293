import React, { useState, useEffect } from 'react';
import {  UserOutlined, LockOutlined,} from '@ant-design/icons';
import './App.css';
import 'antd/dist/antd.css';
import axios from 'axios';
import * as qs from 'query-string';
import { apiRoot } from "./Config";
import { Button, Checkbox, Form, Input, message ,} from 'antd';
import store from 'store2';
import { useParams, useNavigate } from 'react-router-dom';

function Login() {
  const urlParams = qs.parse(window.location.search);
  const params = useParams();
  const [loadings, setLoadings] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const header = store.get('pubkitmobile_auth')
    const user = store.get('pubkitmobile_user')     
    if (header && user) {
          navigate({pathname:'/home'})
        }
    }, []);
  const onFinish = (values) => {
    setLoadings(true)
    values['device']="mobile"
    axios.post(`https://pubkit.newgen.co/submissions/authenticateparams`, values)
        .then(res => {
            if (res.data.status != 200) {
                message.error('Sorry, something went wrong.');
                setLoadings(false)
                return;
            }
            store("pubkitmobile_auth", res.data["authToken"]);
            store("pubkitmobile_user", res.data["user"]);
            setLoadings(false)
            setTimeout( ()=> {navigate({ pathname: '/home' })
            let header = store.get('pubkitmobile_auth')
            let user = store.get('pubkitmobile_user')
            setLoadings(false)
        }, 2000);
        }).catch(function (error) {
            console.log(error);
            message.error('Sorry, something went wrong.');
            setLoadings(false)
        });
  }

  return (
    <div className='loginform'>
             <img src='/pubkit-logo.png' className='logo' />
                    <Form size='large'
                    className='login-form'
                        name="normal_login"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Please input your Username!' }]}
                        >
                            <Input style={{height:'50px',fontSize:'15px'}} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input
                            style={{height:'50px',fontSize:'15px'}}
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item  name="remember" valuePropName="checked" noStyle>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                <Checkbox>Remember Me</Checkbox>
                                <a href="https://pubkit.newgen.co/forgot_password" target="_blank">Forgot Password?</a>
                                </div>
                                
                            </Form.Item>

                           
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={loadings}>
                                LOGIN
                            </Button>

                        </Form.Item>
                    </Form>
            </div>
  );
}

export default Login;
