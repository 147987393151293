import logo from './logo.svg';
import './App.css';
import Login from "./Login";
// import Register from "./Register";
// import Freelancer from "./Freelancer";
// import Insurance from "./Insurance";
// import DomainMails from "./DomainMails";
import UserMails from "./UserMails";
import { BrowserView, MobileView, } from 'react-device-detect';
import { Button, Result } from 'antd';

// import Manager from "./Manager";
import 'antd/dist/antd.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import Application from './Application';
// import Autologin from './Autologin';
// import Links from './Links';
// import Chat from './Chat';
// import AttendanceRegularization from "./AttendanceRegularization"


function App() {
  return (
    <>
      <BrowserView>
        <Result
          status="warning"
          title="This content is available only on mobile" 
          extra={
            <div>
              <p>Please scan the QR code from your hand device or<br/>
                visit  https://mpubkit.newgen.co/ from your hand device.</p>
              <img style={{width:'200px',height:'200px'}} src='https://s3.amazonaws.com/newgen-docs/qrcode-pk_mobile_app.png' alt='qr'></img>
            </div>
          }
            />
     
    
       </BrowserView>
       <MobileView>
        <BrowserRouter >
          <Routes>
            <Route path="/" element={<Login />} />
            {/* <Route path="/login/:message" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-email/:fuzzyid" element={<Register />} />
          <Route path="/freelancer" element={<Freelancer />} />
          <Route path="/freelancer/applications/status" element={<Application />} />
          <Route path="/freelancer/applications/status/:status" element={<Application />} />
          <Route path="/freelancer/:page" element={<Freelancer />} />
          <Route path="/manager" element={<Manager props={{"page":""}}/>} />
          <Route path="/manager/freelancers" element={<Manager props={{"page":"freelancers"}}/>} />
          <Route path="/manager/applications" element={<Manager props={{"page":"applications"}}/>} />
          <Route path="/manager/application/:fuzzyid" element={<Application props={{"page":"applications"}}/>} />
          <Route path="/manager/projects" element={<Manager props={{"page":"projects"}}/>} />
          <Route path="/admin" element={<Manager />} />
          <Route path="/hr/insurance/:page/:fuzzyid" element={<Insurance />} />
          <Route path="/hr/payroll/attendance" element={<AttendanceRegularization />} />
          <Route path="/hr/emails/:page/" element={<DomainMails />} />
          <Route path="/hr/emails/:page/:unithead" element={<DomainMails />} />
          <Route path="/hr/emails/:page/:unithead" element={<DomainMails />} />
          <Route path="/autologin/miscellaneous" element={<Autologin />} /> */}
            <Route path="/home" element={<UserMails />} />
            <Route path="/mail/" element={<UserMails />} />
            <Route path="/compose" element={<UserMails />} />
            <Route path="/replyall" element={<UserMails />} />

            {/* <Route path="/user/chats" element={<Chat />} />
          <Route path="/links" element={<Links />} /> */}
          </Routes>
        </BrowserRouter>
     </MobileView>
     </>
  );
}

export default App;
